import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import Template from '@clearscore/shared.website-template';
import {
    useTracking,
    CareersNav,
    FullWidthImage,
    SectionHeader,
    VideoModal,
    ShadedBox,
    JobListings,
    GLASSDOOR_RATING,
} from '@clearscore/shared.website-careers-components';
import ClickableIcon from '@clearscore/ui.rainbow.clickable-icon';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Text from '@clearscore/ui.rainbow.text';
import { fontSize } from '@clearscore/ui.rainbow.tokens';
import PlayIcon from '@clearscore/rainbow.icons.play-solid';
import ArrowDownIcon from '@clearscore/rainbow.icons.arrow-down';
import useMarketConfig from '@clearscore-group/lib.hooks.use-market-config-deprecated';
import canUseDom from '@clearscore-group/lib.helpers.can-use-dom';
import Link from 'gatsby-link';

import ShadedBoxContent from './components/shaded-box-content';
import marketConfig from './lib/market-config';
import { PAGE_NAMESPACE } from './lib/constants';
import styles from './careers-homepage.module.css';

const NAV_OFFSET = 55;

const CareersHomepage = ({ images, videos, appStoreConfig, cookiePolicyConfig, categories }) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const {
        jobListings: jobListingsConfig,
        boxSections: boxSectionsConfig,
        lowerImage: lowerImageConfig,
    } = useMarketConfig(marketConfig);
    const { trackCareersContentClicked } = useTracking();
    const [isModalOpen, setModalOpen] = useState(false);
    const jobListingsCopy = {
        ...t('workable'),
        glassdoorText: t('workable.glassdoorText', { rating: GLASSDOOR_RATING }),
    };

    /*
        Scroll to the first section header
        We can't use scrollIntoView beacuse of the sticky nav
    */
    const onArrowClick = () => {
        if (!canUseDom()) return;

        const firstHeader = document.querySelector('[data-qa="section-header"]');

        if (!firstHeader?.getBoundingClientRect) return;

        window.scrollTo({
            top: firstHeader.getBoundingClientRect().top + window.pageYOffset - NAV_OFFSET,
            behavior: 'smooth',
        });
    };

    return (
        <Template shouldHideNav appStoreConfig={appStoreConfig} cookiePolicyConfig={cookiePolicyConfig}>
            <CareersNav items={categories} theme={CareersNav.Theme.TRANSPARENT} />
            <article data-layout="full-bleed">
                <div className={styles.heroWrapper}>
                    <FullWidthImage isTallMobile image={images.hero} alt="People working">
                        <div className={styles.heroImageText}>
                            <Spacer all={{ bottom: Spacer.spacings.SMALL }}>
                                <Text.H4 tag="span">{t('hero.preheader')}</Text.H4>
                            </Spacer>
                            <h1>{t('hero.header')}</h1>
                            <Spacer all={{ top: Spacer.spacings.BIG }}>
                                <ClickableIcon onClick={onArrowClick}>
                                    <ArrowDownIcon
                                        width={fontSize['rnb-font-size-massive']}
                                        height={fontSize['rnb-font-size-massive']}
                                    />
                                </ClickableIcon>
                            </Spacer>
                        </div>
                    </FullWidthImage>
                </div>
                <SectionHeader header={t('pageHeader.header')} subheader={t('pageHeader.subheader')}>
                    <Text.Button2
                        onClick={() => {
                            setModalOpen(true);
                            trackCareersContentClicked({ type: 'video', description: t('pageHeader.ctaText') });
                        }}
                        dataId="watch-film"
                    >
                        <div className={cx(styles.verticalCentered, styles.horizontallyCentered, styles.clickable)}>
                            <PlayIcon width={fontSize['rnb-font-size-big']} height={fontSize['rnb-font-size-big']} />
                            {t('pageHeader.ctaText')}
                        </div>
                    </Text.Button2>
                </SectionHeader>
                <Spacer all={{ bottom: Spacer.spacings.SUPER }} />
                {boxSectionsConfig.map(({ key, icon, theme, ctaHref }, idx) => (
                    <section key={key} data-qa="shaded-box-section">
                        <ShadedBox
                            isFlipped={idx % 2 > 0}
                            header={t(`boxSections.${key}.header`)}
                            theme={theme}
                            image={images[key]}
                            alt={t(`boxSections.${key}.img.alt`)}
                        >
                            <ShadedBoxContent
                                ctaHref={ctaHref}
                                body={t(`boxSections.${key}.body`)}
                                ctaText={t(`boxSections.${key}.ctaText`)}
                                iconCaption={t(`boxSections.${key}.iconCaption`)}
                                icon={icon}
                            />
                        </ShadedBox>
                    </section>
                ))}
                <FullWidthImage isTallMobile image={images.lowerHero} alt="Mountain">
                    <div className={styles.lowerImageText}>
                        <div>{t('lowerImage.header')}</div>
                        <Spacer all={{ top: Spacer.spacings.MEDIUM, bottom: Spacer.spacings.BIG }}>
                            <div className={styles.lowerImageBody}>{t('lowerImage.body')}</div>
                        </Spacer>
                        <Link to={lowerImageConfig.ctaHref}>{t('lowerImage.ctaText')}</Link>
                    </div>
                </FullWidthImage>
                <JobListings language={jobListingsCopy} config={jobListingsConfig} />
            </article>
            <VideoModal isOpen={isModalOpen} video={videos.main} subtitles="" handleClose={() => setModalOpen(false)} />
        </Template>
    );
};

CareersHomepage.propTypes = {
    images: PropTypes.shape({
        hero: PropTypes.object.isRequired,
        lowerHero: PropTypes.object.isRequired,
    }).isRequired,
    videos: PropTypes.shape({
        main: PropTypes.string.isRequired,
    }).isRequired,
    appStoreConfig: PropTypes.shape({
        googlePlayUrl: PropTypes.string,
        appStoreUrl: PropTypes.string,
        appStoreLink: PropTypes.string,
        playStoreLink: PropTypes.string,
        appleStoreToggle: PropTypes.bool,
        googleStoreToggle: PropTypes.bool,
    }).isRequired,
    cookiePolicyConfig: PropTypes.shape({
        moreInfoLinkUrl: PropTypes.string.isRequired,
        enabled: PropTypes.bool.isRequired,
    }).isRequired,
    categories: PropTypes.arrayOf(
        PropTypes.shape({
            categoryNavPosition: PropTypes.number.isRequired,
            categoryUrl: PropTypes.string.isRequired,
            navTitle: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

CareersHomepage.defaultProps = {};

export default CareersHomepage;
