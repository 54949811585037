import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Spacer from '@clearscore/ui.rainbow.spacer';
import Text from '@clearscore/ui.rainbow.text';
import { fontSize } from '@clearscore/ui.rainbow.tokens';
import Link from 'gatsby-link';

import styles from './shaded-box-content.module.css';

const ShadedBoxContent = ({ body, ctaHref, ctaText, iconCaption, icon: Icon }) => (
    <Fragment>
        <Text.Body1>{body}</Text.Body1>
        {ctaText && ctaHref ? (
            <Spacer all={{ top: Spacer.spacings.BIG, bottom: Spacer.spacings.HUGE }}>
                <Link to={ctaHref} className={styles.link}>
                    {ctaText}
                </Link>
            </Spacer>
        ) : (
            <Spacer all={{ top: Spacer.spacings.BIG }} />
        )}
        <div className={styles.verticalCentered}>
            <Icon width={fontSize['rnb-font-size-big']} height={fontSize['rnb-font-size-big']} />
            <Spacer all={{ left: Spacer.spacings.MEDIUM }}>
                <Text.Body1>{iconCaption}</Text.Body1>
            </Spacer>
        </div>
    </Fragment>
);

ShadedBoxContent.propTypes = {
    body: PropTypes.string.isRequired,
    iconCaption: PropTypes.string.isRequired,
    icon: PropTypes.elementType.isRequired,
    ctaText: PropTypes.string,
    ctaHref: PropTypes.string,
};

ShadedBoxContent.defaultProps = {
    ctaText: '',
    ctaHref: '',
};

export default ShadedBoxContent;
