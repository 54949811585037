import React, { Fragment } from 'react';
import type { PageProps } from 'gatsby';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import CareersHomepage from '@clearscore/website.careers-homepage';

import homepageVideo from '../assets/global/careers/homepage/homepageVideo.mp4';
import Layout from '../components/layout';
import { findImage, HiddenPageMeta } from './template-util';

const CareersHomepageTemplate = ({
    data,
    pageContext: { market, subNavRoutes, verticalVersion },
    location,
}: PageProps<
    Queries.CareersHomepageQuery,
    { market: string; verticalVersion: string; subNavRoutes: Array<{ navTitle: string; categoryUrl: string }> }
>): React.ReactElement => {
    const images = {
        hero: getImage(findImage(data, 'hero')),
        lowerHero: getImage(findImage(data, 'lower_hero')),
        ourPeopleMatter: getImage(findImage(data, 'our_people_matter')),
        ourUsersMatter: getImage(findImage(data, 'our_users_matter')),
    };

    const videos = {
        main: homepageVideo,
    };

    return (
        <Layout
            meta={{
                title: `Careers at ClearScore ${market.toUpperCase()}`,
                description: '',
            }}
            location={location.pathname}
        >
            {({ commonModules }): React.ReactElement => (
                <Fragment>
                    <CareersHomepage
                        images={images}
                        videos={videos}
                        appStoreConfig={commonModules.app_store}
                        cookiePolicyConfig={commonModules.cookie_policy}
                        categories={subNavRoutes}
                    />
                    <HiddenPageMeta verticalVersion={verticalVersion} />
                </Fragment>
            )}
        </Layout>
    );
};

export const query = graphql`
    query CareersHomepage {
        images: allFile(filter: { absolutePath: { regex: "/global/careers/homepage/" } }) {
            nodes {
                name
                childImageSharp {
                    gatsbyImageData(quality: 99, breakpoints: [375, 768, 1024, 1200, 1440], layout: FULL_WIDTH)
                }
            }
        }
    }
`;

export default CareersHomepageTemplate;
