import { GB } from '@clearscore-group/lib.config.i18n';
import AccountIcon from '@clearscore/rainbow.icons.account';
import { ShadedBox } from '@clearscore/shared.website-careers-components';

export default {
    [GB]: {
        jobListings: {
            viewAllJobs: '/careers/jobs?expand=1',
            maxJobsDisplayed: 5,
        },
        boxSections: [
            {
                key: 'ourPeopleMatter',
                theme: ShadedBox.Theme.SAND,
                ctaHref: '/careers/life',
                icon: AccountIcon,
            },
            {
                key: 'ourUsersMatter',
                theme: ShadedBox.Theme.CHILL,
                icon: AccountIcon,
            },
        ],
        lowerImage: {
            ctaHref: '/careers/our-teams',
        },
    },
};
